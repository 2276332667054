import { APIResult } from "../../../common/src/api/document/types";
import {
  SubscriptionDetails,
  SubscriptionInvoices,
  SubscriptionSummary,
} from "../../../common/src/api/subscriptions/subscription";
import { Subscription } from "../../../common/src/models/Subscription";
import { ApiClient } from "./ApiClient";

const subscriptionApi = new ApiClient("/api/subscription");

export async function getAllSubscriptions(): Promise<
  APIResult<Subscription[]>
> {
  return subscriptionApi.get("all");
}

export async function associateSubscription(
  subscriptionId: number,
  orgId: string,
): Promise<APIResult<Subscription>> {
  return subscriptionApi.post("associate", {
    subscriptionId,
    orgId,
  });
}

export async function deleteSubscription(id: string): Promise<APIResult<void>> {
  return subscriptionApi.delete(id);
}

export async function getSubscriptionSummary(
  orgId: string,
): Promise<APIResult<SubscriptionSummary>> {
  return subscriptionApi.get(`org/${orgId}/summary`);
}

export async function getSubscriptionDetailsByOrg(
  orgId: string,
): Promise<APIResult<SubscriptionDetails>> {
  return subscriptionApi.get(`org/${orgId}/details`);
}

export async function getSubscriptionInvoices(
  orgId: string,
): Promise<APIResult<SubscriptionInvoices>> {
  return subscriptionApi.get(`org/${orgId}/invoices`);
}

export async function linkSubscriptionToAccount(
  subscriptionId: string,
  customerId: string,
  customerReference: string,
): Promise<APIResult<string>> {
  return subscriptionApi.post("associate/maxio-callback", {
    subscriptionId,
    customerId,
    customerReference,
  });
}
