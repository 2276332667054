export const ICONS = [
  "assistant-suggestion",
  "assistant-warning",
  "assistant-error",
  "arrow-down",
  "arrow-up",
  "chevron-down",
  "chevron-up",
  "chevron-right",
  "chevron-left",
  "ellipsis-vertical",
  "settings",
  "history",
  "eye",
  "checkmark",
  "share",
  "restore",
  "h2x",
  "save-spinner-progress",
  "save-spinner-background",
  "search",
  "file-check",
  "file-warning",
  "target",
  "fix",
  "chat",
];
export type Icon = (typeof ICONS)[number];

export const ICON_SIZES = ["xxs", "xs", "sm", "md", "lg", "xl", "2xl"];
export type IconSize = (typeof ICON_SIZES)[number];

export function iconSizeToPx(size: IconSize): number {
  switch (size) {
    case "xxs":
      return 12;
    case "xs":
      return 16;
    case "sm":
      return 20;
    case "lg":
      return 32;
    case "xl":
      return 48;
    case "2xl":
      return 64;
    case "md":
    default:
      return 24;
  }
}
