<template>
  <div>
    <MainNavBar />
    <div
      style="overflow-y: auto; overflow-x: hidden; height: calc(100vh - 70px)"
    >
      <b-container>
        <b-row class="mt-3">
          <b-col>
            <b-button class="float-left" to="/organizations"> Back </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2>View / Edit Organization</h2>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col>
            <template v-if="org">
              <b-form class="text-left">
                <h3>General</h3>
                <p>General settings of your organization.</p>
                <br />

                <b-form-group :label-cols="3" label="Organization ID">
                  <b-form-input
                    v-model="org.id"
                    plaintext
                    disabled
                    class="plaintextbox"
                  />
                </b-form-group>

                <b-form-group :label-cols="3" label="Organization Name">
                  <b-form-input
                    v-model="org.name"
                    :plaintext="profile.accessLevel > AccessLevel.MANAGER"
                    class="plaintextbox"
                  />
                </b-form-group>

                <b-form-group
                  v-if="canEditOrganizationType"
                  :label-cols="3"
                  label="Organization Type"
                  class="linkbox"
                >
                  <b-radio-group
                    v-model="org.type"
                    :disabled="!canEditOrganizationType"
                  >
                    <b-radio :value="types.BUILDING_SERVICES">
                      Building Services
                    </b-radio>
                    <b-radio :value="types.MANUFACTURER">
                      Manufacturer
                    </b-radio>
                  </b-radio-group>
                </b-form-group>

                <b-form-group
                  v-if="canEditOrganizationSubscriptionStatus"
                  :label-cols="3"
                  label="Subscription Type"
                  class="linkbox"
                >
                  <b-radio-group v-model="org.subscriptionStatus">
                    <b-radio :value="subscriptionStatusEnum.FREE">
                      Free
                    </b-radio>
                    <b-radio :value="subscriptionStatusEnum.PAID">
                      Paid
                    </b-radio>
                    <b-radio :value="subscriptionStatusEnum.CHURNED">
                      Churned
                    </b-radio>
                  </b-radio-group>
                </b-form-group>

                <b-form-group
                  v-if="canEditEnforceMFA"
                  :label-cols="3"
                  label="Enforce Multi-factor Authentication"
                >
                  <b-form-checkbox
                    v-model="org.enforceMFA"
                    name="check-button"
                    switch
                  >
                    {{ org.enforceMFA ? "Enabled" : "Disabled" }} for everyone
                    in this organization
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group
                  v-if="canEditFeatureAccess && orgFullData"
                  label-cols="3"
                  label="Feature Access"
                >
                  <b-form-checkbox
                    v-model="orgFullData.featureAccess.revitSync"
                  >
                    Revit Sync
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="orgFullData.featureAccess.marketplaceVerified"
                  >
                    Marketplace Verified
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="orgFullData.featureAccess.autocadExport"
                  >
                    Autocad Export
                  </b-form-checkbox>
                  <b-form-checkbox v-model="orgFullData.featureAccess.metaPdf">
                    Custom Titleblocks, Cover Sheets
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="
                      orgFullData.featureAccess.fullUnderfloorHeatingLoops
                    "
                  >
                    Full Underfloor Heating Loops
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group
                  v-if="profile.accessLevel <= AccessLevel.MANAGER"
                  :label-cols="3"
                  label="Users"
                  class="linkbox"
                >
                  <b-link
                    :to="{
                      path: '/users',
                      query: { orgId: org.id },
                    }"
                  >
                    {{ usersNum }} users
                  </b-link>
                </b-form-group>

                <b-form-group
                  v-if="canEditOrganizationMaxAccountsNum && orgFullData"
                  :label-cols="3"
                  label="Max number of users"
                >
                  <b-form-input
                    v-model.number="orgFullData.maxAccountsNum"
                    :plaintext="!canEditOrganizationMaxAccountsNum"
                    :disabled="!canEditOrganizationMaxAccountsNum"
                    type="number"
                  />
                </b-form-group>

                <b-form-group
                  v-if="profile && canMergeOrganization"
                  :label-cols="3"
                  label="Merge Organization To"
                >
                  <MultiSelect
                    v-model="mergeTargetOrganization"
                    placeholder="Search..."
                    :options="filteredOrganizations"
                    label="name"
                    track-by="id"
                    :custom-label="customOrgsSelectLabel"
                  />
                </b-form-group>

                <b-form-group
                  v-if="
                    org.type === OrganizationType.MANUFACTURER &&
                    profile.accessLevel < AccessLevel.MANAGER
                  "
                  :label-cols="3"
                  label="Part Competitors"
                >
                  <MultiSelect
                    v-model="partCompetitors"
                    placeholder="Search..."
                    :options="partTypes"
                    :multiple="true"
                    :clear-on-select="false"
                  />
                </b-form-group>
              </b-form>

              <!-- image for square -->
              <b-form class="text-left">
                <b-form-group
                  :label-cols="3"
                  label="Organization Logo (Square)"
                >
                  <b-img
                    :src="getTransientLogoSquareSrc || remoteLogoSquareSrcUrl"
                    style="
                      width: 100px;
                      height: 100px;
                      object-fit: contain;
                      object-position: center;
                    "
                    alt="Organization logo square"
                  />
                </b-form-group>
              </b-form>

              <!-- form input for square -->
              <b-form class="text-left">
                <b-form-group
                  :label-cols="3"
                  label="Update Organization Logo (Square)"
                >
                  <b-form-file
                    id="image-upload-square"
                    v-model="transientLogoSquare"
                    accept="image/jpeg, image/png, image/svg+xml, image/webp"
                    placeholder="Upload an (approximately) square version of your organization's logo"
                  />
                </b-form-group>
              </b-form>

              <!-- image for wide -->
              <b-form class="text-left">
                <b-form-group :label-cols="3" label="Organization Logo (Wide)">
                  <b-img
                    :src="getTransientLogoWideSrc || remoteLogoWideSrcUrl"
                    style="
                      width: 162px;
                      height: 100px;
                      object-fit: contain;
                      object-position: center;
                    "
                    alt="Organization logo wide"
                  />
                </b-form-group>
              </b-form>

              <!-- form input for wide -->
              <b-form class="text-left">
                <b-form-group
                  :label-cols="3"
                  label="Update Organization Logo (Wide)"
                >
                  <b-form-file
                    id="image-upload-wide"
                    v-model="transientLogoWide"
                    accept="image/jpeg, image/png, image/svg+xml, image/webp"
                    placeholder="Upload a wide (rectangular) version of your organization's logo"
                  />
                </b-form-group>
              </b-form>

              <b-form class="text-left">
                <b-form-group :label-cols="3" label="Organization Address">
                  <b-form-input
                    v-model="org.address"
                    :plaintext="profile.accessLevel > AccessLevel.MANAGER"
                    class="plaintextbox"
                  />
                </b-form-group>
              </b-form>

              <b-form class="text-left">
                <b-form-group :label-cols="3" label="Organization Phone Number">
                  <b-form-input
                    v-model="org.phoneNumber"
                    :plaintext="profile.accessLevel > AccessLevel.MANAGER"
                    class="plaintextbox"
                  />
                </b-form-group>
              </b-form>
              <b-row
                v-if="canViewSubscriptionPage"
                class="mx-auto justify-content-center"
              >
                <b-button
                  :to="{
                    name: 'organization-subscription',
                    params: { id: org?.id },
                  }"
                >
                  View Subscription
                </b-button>
              </b-row>
              <b-form class="text-left">
                <b-form-group
                  v-if="
                    profile.accessLevel <= AccessLevel.MANAGER &&
                    org.type === OrganizationType.MANUFACTURER
                  "
                  :label-cols="3"
                  label="Locales supplied"
                >
                  <a-select
                    v-model="localesSupplied"
                    :options="options"
                    mode="multiple"
                    placeholder="Select locales"
                    class="max-w-[300px]"
                    :disabled="profile.accessLevel >= AccessLevel.MANAGER"
                    :title="
                      profile.accessLevel >= AccessLevel.MANAGER
                        ? 'Please contact h2x admin if you also supply products to other locales!'
                        : ''
                    "
                  />
                </b-form-group>
              </b-form>
              <br /><br />
              <div v-if="orgFullData">
                <b-form v-if="enableSecurity" class="text-left">
                  <h3>Security</h3>
                  <p>
                    The security settings below are set for users of this
                    organization.
                  </p>
                  <br />

                  <b-form-group
                    v-if="canEditMaxPasswordAttemptsBeforeLock"
                    :label-cols="3"
                    label="Max password attempts before lockout"
                  >
                    <b-form-input
                      v-model.number="orgFullData.maxPasswordAttemptsBeforeLock"
                      :plaintext="!canEditMaxPasswordAttemptsBeforeLock"
                      :disabled="!canEditMaxPasswordAttemptsBeforeLock"
                      type="number"
                    />
                  </b-form-group>

                  <b-form-group :label-cols="3" label="Password minimum length">
                    <b-form-input
                      v-model.number="
                        orgFullData.passwordRequirements.minLength
                      "
                      :plaintext="profile.accessLevel > AccessLevel.MANAGER"
                      :disabled="profile.accessLevel > AccessLevel.MANAGER"
                      type="number"
                    />
                  </b-form-group>

                  <b-form-group
                    :label-cols="3"
                    label="Password minimum numbers"
                  >
                    <b-form-input
                      v-model.number="
                        orgFullData.passwordRequirements.minNumbers
                      "
                      :plaintext="profile.accessLevel > AccessLevel.MANAGER"
                      :disabled="profile.accessLevel > AccessLevel.MANAGER"
                      type="number"
                    />
                  </b-form-group>

                  <b-form-group
                    :label-cols="3"
                    label="Password minimum special characters"
                  >
                    <b-form-input
                      v-model.number="
                        orgFullData.passwordRequirements.minSpecialChars
                      "
                      :plaintext="profile.accessLevel > AccessLevel.MANAGER"
                      :disabled="profile.accessLevel > AccessLevel.MANAGER"
                      type="number"
                    />
                  </b-form-group>

                  <b-form-group
                    :label-cols="3"
                    label="Password minimum lowercase letters"
                  >
                    <b-form-input
                      v-model.number="
                        orgFullData.passwordRequirements.minLowercase
                      "
                      :plaintext="profile.accessLevel > AccessLevel.MANAGER"
                      :disabled="profile.accessLevel > AccessLevel.MANAGER"
                      type="number"
                    />
                  </b-form-group>

                  <b-form-group
                    :label-cols="3"
                    label="Password minimum uppercase letters"
                  >
                    <b-form-input
                      v-model.number="
                        orgFullData.passwordRequirements.minUppercase
                      "
                      :plaintext="profile.accessLevel > AccessLevel.MANAGER"
                      :disabled="profile.accessLevel > AccessLevel.MANAGER"
                      type="number"
                    />
                  </b-form-group>
                </b-form>
              </div>

              <br /><br />
              <b-button
                v-if="profile.accessLevel <= AccessLevel.MANAGER"
                variant="primary"
                @click="save"
              >
                <strong>Save</strong>
              </b-button>
              &nbsp;
              <b-button
                v-if="profile.accessLevel <= AccessLevel.MANAGER"
                variant="primary"
                :to="{ path: '/users/create', query: { orgId: org.id } }"
              >
                <strong>Create User</strong>
              </b-button>
              &nbsp;
              <b-button
                v-if="canMergeOrganization"
                v-b-modal.confirmMerge
                variant="primary"
              >
                <strong>Merge Organization</strong>
              </b-button>
              <b-modal
                v-if="profile && canMergeOrganization"
                id="confirmMerge"
                title="Warning:  Are you sure you want to merge organizations?"
                ok-variant="danger"
                ok-title="Yes"
                @ok="mergeOrg"
              >
                This irreversible operation will move all users, documents and
                part-associations from current organization to the selected
                organization.
              </b-modal>
              <br /><br /><br /><br />
            </template>
            <b-alert v-else variant="succßess" show> Loading... </b-alert>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { defineComponent } from "vue";
import { getManufacturerDirectory } from "../../../common/src/api/catalog/manufacturers/utils";
import { LOCALE_NAMES, SupportedLocales } from "../../../common/src/api/locale";
import {
  Organization as IOrganization,
  Organization,
  OrganizationRestrictedView,
  OrganizationSubscriptionStatus,
  OrganizationType,
  customOrgsLabel,
  orgTypeToLabel,
} from "../../../common/src/models/Organization";
import { AccessLevel, User } from "../../../common/src/models/User";
import { validatePasswordRequirement } from "../../../common/src/models/User.validation";
import {
  getPartCompetitorByOrganization,
  updatePartCompetitors,
} from "../api/manufacturer";
import {
  getLocalesSupplied,
  getOrgLogoUrl,
  getOrganization,
  getOrganizationRestricted,
  getOrganizations,
  mergeOrganization,
  updateOrganization,
  uploadOrgLogo,
} from "../api/organizations";
import { getUsers } from "../api/users";
import MainNavBar from "../components/MainNavBar.vue";
import { isFeatureFlagEnabled } from "../lib/hooks/use-feature-flag";

export default defineComponent({
  components: { MainNavBar },
  data() {
    const remoteLogoWideSrcUrl = null as null | string;
    const transientLogoWide = null as null | File;
    const remoteLogoSquareSrcUrl = null as null | string;
    const transientLogoSquare = null as null | File;
    const partTypes: String[] = getManufacturerDirectory().map(
      (ele) => ele.partType,
    );
    const partCompetitors: String[] = [];
    const currentPartCompetitors: String[] = [];
    const users: User[] = [];
    const mergeTargetOrganization = null as IOrganization | null;
    const organizations: IOrganization[] = [];
    const org = null as OrganizationRestrictedView | null;
    const orgFullData = null as Organization | null;
    const options = Object.entries(LOCALE_NAMES).map(([value, label]) => ({
      label,
      value,
    }));

    return {
      org,
      orgFullData,
      localesSupplied: [] as SupportedLocales[],
      organizations,
      mergeTargetOrganization,
      users,
      currentPartCompetitors,
      partCompetitors,
      partTypes,
      transientLogoSquare,
      remoteLogoSquareSrcUrl,
      transientLogoWide,
      remoteLogoWideSrcUrl,
      enableSecurity: false,
      orgTypeToLabel: orgTypeToLabel,
      options,
    };
  },
  computed: {
    filteredOrganizations() {
      return this.organizations.filter(
        (organization) => organization.type === this.org?.type,
      );
    },
    types() {
      return OrganizationType;
    },
    subscriptionStatusEnum() {
      return OrganizationSubscriptionStatus;
    },
    profile(): User {
      return this.$store.getters["profile/profile"];
    },
    canEditOrganizationType() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    canEditOrganizationMaxAccountsNum() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    canEditMaxPasswordAttemptsBeforeLock() {
      return this.profile.accessLevel <= AccessLevel.MANAGER;
    },
    canMergeOrganization() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    canEditOrganizationSubscriptionStatus() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    canEditEnforceMFA() {
      return this.profile.accessLevel <= AccessLevel.MANAGER;
    },
    canEditFeatureAccess() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    canViewSubscriptionPage() {
      return (
        this.profile.accessLevel <= AccessLevel.ADMIN &&
        isFeatureFlagEnabled("release-view-subscription-page")
      );
    },
    customOrgsSelectLabel() {
      return customOrgsLabel;
    },
    usersNum() {
      if (this.users.length === 0) {
        return "Loading...";
      }
      return this.users.filter((ele) => ele.organization?.id === this.org?.id)
        .length;
    },
    AccessLevel() {
      return AccessLevel;
    },
    OrganizationType() {
      return OrganizationType;
    },
    getTransientLogoSquareSrc() {
      if (!this.transientLogoSquare) return null;
      return URL.createObjectURL(this.transientLogoSquare);
    },
    getTransientLogoWideSrc() {
      if (!this.transientLogoWide) return null;
      return URL.createObjectURL(this.transientLogoWide);
    },
  },

  mounted() {
    if (this.profile && this.profile.accessLevel <= AccessLevel.ADMIN) {
      // fill documents
      getOrganizations().then((res) => {
        if (res.success) {
          this.organizations.splice(0, this.organizations.length, ...res.data);
        } else {
          this.$bvToast.toast(res.message, {
            variant: "danger",
            title: "Error retrieving org list",
          });
        }
      });
    }
    getUsers().then((res) => {
      if (res.success) {
        this.users.splice(0, this.users.length, ...res.data);
      } else {
        this.$bvToast.toast(res.message, {
          variant: "danger",
          title: "Error retrieving user list",
        });
      }
    });

    // OV semantically this is wrong but it's how it was in the past as a class component so I leave this
    // confusingly as called 'beforeCreate'
    this.beforeCreate();
  },
  methods: {
    async beforeCreate() {
      // get the organization
      const getFullData = this.profile.accessLevel <= AccessLevel.MANAGER;
      const orgRequest = await (getFullData
        ? getOrganization(this.$route.params.id)
        : getOrganizationRestricted(this.$route.params.id));
      if (!orgRequest.success) {
        this.$bvToast.toast(orgRequest.message, {
          title: "Error retrieving data about organization",
          variant: "danger",
        });
        return;
      }

      this.org = orgRequest.data;
      if (getFullData) {
        // @ts-ignore: typescript doesn't know ' know that we have the full Organization data
        this.orgFullData = orgRequest.data;
      }

      // get remote logo urls
      (async () => {
        const urlRes = await getOrgLogoUrl(this.org!.id, "medium", "square");
        if (!urlRes.success) return;
        this.remoteLogoSquareSrcUrl = urlRes.data;
      })();
      (async () => {
        const urlRes = await getOrgLogoUrl(this.org!.id, "medium", "wide");
        if (!urlRes.success) return;
        this.remoteLogoWideSrcUrl = urlRes.data;
      })();

      if (
        this.org &&
        this.org.type === OrganizationType.MANUFACTURER &&
        this.profile.accessLevel <= AccessLevel.MANAGER
      ) {
        getLocalesSupplied(this.org.id).then((res) => {
          if (res.success) {
            this.localesSupplied = res.data;
          } else {
            this.$bvToast.toast(res.message, {
              title: "Error retriving locales supplied",
              variant: "danger",
            });
          }
        });
      }
      if (
        this.org &&
        this.org.type === OrganizationType.MANUFACTURER &&
        this.profile.accessLevel <= AccessLevel.ADMIN
      ) {
        getPartCompetitorByOrganization(this.org.id).then((res) => {
          if (res.success) {
            this.partCompetitors = res.data.map((ele) => ele.partType);
            this.currentPartCompetitors = this.partCompetitors;
          } else {
            this.$bvToast.toast(res.message, {
              title: "Error retriving part competitors",
              variant: "danger",
            });
          }
        });
      }
    },
    mergeOrg() {
      if (this.mergeTargetOrganization == null) {
        this.$bvToast.toast(
          "Please check your target organization for merging again",
          {
            title: "Error merging organizations",
            variant: "danger",
          },
        );
      } else if (this.org == null) {
        this.$bvToast.toast("Current organization can't be null'", {
          title: "Error merging organizations",
          variant: "danger",
        });
      } else if (this.org.id === this.mergeTargetOrganization.id) {
        this.$bvToast.toast("You cannot merge to the same organization", {
          title: "Error merging organizations",
          variant: "danger",
        });
      } else if (this.org.type !== this.mergeTargetOrganization.type) {
        this.$bvToast.toast(
          "Target organization should have the same type as the current organization",
          {
            title: "Error merging organizations",
            variant: "danger",
          },
        );
      } else {
        mergeOrganization(this.org!.id, this.mergeTargetOrganization!.id).then(
          (res) => {
            if (res.success) {
              const message = `You have successfully merged ${res.data.migratedUsersNum} users, ${res.data.migratedDocumentsNum} documents, ${res.data.migratedSubscriptionsNum} subscriptions, ${res.data.migratedPartAssociationsNum} part associations and ${res.data.migratedPartCompetitorsNum} part competitors from ${this.org?.name} to ${this.mergeTargetOrganization?.name}`;
              this.$router.push("/organizations").then(() => {
                this.$bvToast.toast(message, {
                  title: "Merged organizations successfully",
                  variant: "primary",
                });
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: "Error merging organizations",
                variant: "danger",
              });
            }
          },
        );
      }
    },
    async save() {
      const processOrgMetaData = async () => {
        if (!this.orgFullData) return;

        // coerce maxAccountsNum value
        // @ts-ignore: number can't equal to empty string error
        if (this.orgFullData.maxAccountsNum === "") {
          this.orgFullData.maxAccountsNum = null;
        }

        // validate security settings
        const passwordReqValidation = validatePasswordRequirement(
          this.orgFullData.passwordRequirements,
        );
        if (!passwordReqValidation.success) {
          this.$bvToast.toast(passwordReqValidation.message, {
            title: "Error in your password requirements",
            variant: "danger",
          });
          return;
        }

        // send plain metadata to backend
        const metaDataUploadResult = await updateOrganization(
          this.orgFullData.id,
          {
            ...this.orgFullData,
            localesSupplied: this.localesSupplied,
          },
        );
        if (!metaDataUploadResult.success) {
          this.$bvToast.toast(metaDataUploadResult.message, {
            title: "Error saving organization metadata",
            variant: "danger",
          });
          return;
        }
        this.$bvToast.toast(
          "You have successfully saved changes to the organization",
          {
            title: "Saved organization successfully",
            variant: "primary",
          },
        );
      };

      const processSquareLogo = async () => {
        if (!this.org) return;
        if (this.transientLogoSquare) {
          const file = this.transientLogoSquare;
          const fileUploadResult = await uploadOrgLogo(
            this.org.id,
            file,
            "square",
          );
          if (!fileUploadResult.success) {
            this.$bvToast.toast(fileUploadResult.message, {
              title: "Error uploading square organization logo",
              variant: "danger",
            });
            return;
          }
          setTimeout(async () => {
            const urlRes = await getOrgLogoUrl(
              this.org!.id,
              "medium",
              "square",
            ); // regen url for cache-busting
            if (!urlRes.success) return;

            this.remoteLogoSquareSrcUrl = urlRes.data;
            this.transientLogoSquare = null; // clear the preview so it defaults to remote
          }, 1000);

          this.$bvToast.toast(
            "You have successfully uploaded square organization logo",
            {
              title: "Uploaded organization logo successfully",
              variant: "primary",
            },
          );
        }
      };

      const processWideLogo = async () => {
        if (!this.org) return;
        if (this.transientLogoWide) {
          const file = this.transientLogoWide;
          const fileUploadResult = await uploadOrgLogo(
            this.org.id,
            file,
            "wide",
          );
          if (!fileUploadResult.success) {
            this.$bvToast.toast(fileUploadResult.message, {
              title: "Error uploading wide organization logo",
              variant: "danger",
            });
            return;
          }
          setTimeout(async () => {
            const urlRes = await getOrgLogoUrl(this.org!.id, "medium", "wide"); // regen url for cache-busting
            if (!urlRes.success) return;
            this.remoteLogoWideSrcUrl = urlRes.data;
            this.transientLogoWide = null; // clear the preview so it defaults to remote
          }, 1000);
          this.$bvToast.toast(
            "You have successfully uploaded wide organization logo",
            {
              title: "Uploaded organization logo successfully",
              variant: "primary",
            },
          );
        }
      };

      const processPartCompetitors = async () => {
        if (!this.org) return;
        const partCompetitorsHasChanged = !_.isEqual(
          this.partCompetitors.sort(),
          this.currentPartCompetitors.sort(),
        );
        if (partCompetitorsHasChanged) {
          if (this.profile.accessLevel > AccessLevel.ADMIN) {
            // ^ if less privileged than admin...
            this.$bvToast.toast(
              "You are not allowed to update part competitors",
              {
                title: "Error updating part competitors",
                variant: "danger",
              },
            );
            return;
          }
          const partCompetitorsResult = await updatePartCompetitors(
            this.org.id,
            this.partCompetitors,
          );
          if (!partCompetitorsResult.success) {
            this.$bvToast.toast(partCompetitorsResult.message, {
              title: "Error updating part competitors",
              variant: "danger",
            });
          }
          this.$bvToast.toast(
            "You have successfully updated part competitors",
            {
              title: "Updating part competitors successfully",
              variant: "primary",
            },
          );
        }
      };

      await Promise.all([
        processOrgMetaData(),
        processSquareLogo(),
        processWideLogo(),
        processPartCompetitors(),
      ]);
    },
  },
});
</script>

<style scoped>
.linkbox > div > :nth-child(2) {
  align-items: center;
  display: flex;
}

.plaintextbox {
  padding: 0.375rem 0.75rem;
}
</style>
